<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create -->
    <b-modal
      id="modal-create"
      v-model="isModalCreate"
      size="xl"
      centered
      title="Tambah Data Mahasiswa Beasiswa Kerjasama"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-tabs active-nav-item-class="iq-bg-light" no-fade pills card justified>
        <!-- BEGIN:Left -->
        <b-tab
          title="Kerjasama"
          title-link-class="font-weight-bold bg-light mx-2"
          active
        >
          <b-form class="px-4">
            <label for="program">Program:</label
            ><span class="text-danger ml-1">*</span>
            <b-form-group>
              <b-form-radio
                inline
                id="program1"
                v-model="form.program"
                value="1"
                name="program"
                @input="inputJenisProgram(form.program)"
                >Pendas</b-form-radio
              >
              <b-form-radio
                inline
                id="program2"
                v-model="form.program"
                value="2"
                name="program"
                >Non Pendas</b-form-radio
              >
              <b-form-radio
                inline
                id="program3"
                v-model="form.program"
                value="3"
                name="program"
                >PPS</b-form-radio
              >
            </b-form-group>
            <b-form-group>
              <label for="masa">Masa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="masa"
                v-model="form.masa"
                required
                type="number"
                max-length="5"
              />
            </b-form-group>
            <b-form-group>
              <input-datalists
                id="kodePks"
                class="w-full text-sm"
                placeholder="Masukkan 3 digit kode PKS"
                v-model="kodePks"
                label="kode_pks"
                required
                @search="getDataDetailPks"
                :options="listDataPks || []"
                :clearable="false"
              >
                <template #spinner="{ loading }">
                  <div v-if="loading" class="vs__spinner"></div>
                </template>
                <template #header>
                  <label class="block"
                    >Kode PKS: <span class="text-danger">*</span></label
                  >
                </template>
                <template v-slot:option="option">
                  <div class="flex justify-between p-1">
                    {{ option.kode_pks }} | {{ option.nomor_pks_mitra }}
                  </div>
                </template>
              </input-datalists>
            </b-form-group>
            <b-form-group>
              <label for="nomor_pks_ut">Nomor KS UT:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nomor_pks_ut"
                v-model="form.nomor_pks_ut"
                required
                isDetail
              />
            </b-form-group>
            <b-form-group>
              <label for="nomor_pks_mitra">Nomor KS Mitra:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nomor_pks_mitra"
                v-model="form.nomor_pks_mitra"
                required
                isDetail
              />
            </b-form-group>
            <b-form-group>
              <label for="tipe_beasiswa">Tipe Beasiswa:</label>
              <input-primary
                id="tipe_beasiswa"
                v-model="form.tipe_beasiswa"
                required
                isDetail
              />
            </b-form-group>
            <b-form-group>
              <input-datalists
                id="kode_beasiswa"
                class="w-full text-sm"
                :placeholder="
                  form.program == null
                    ? 'Pilih program lebih dulu'
                    : 'Pilih Beasiswa'
                "
                v-model="form.kode_beasiswa"
                label="label"
                required
                :options="listDataBeasiswa || []"
                :reduce="listDataBeasiswa => listDataBeasiswa.value"
                :disabled="form.program == null"
                :clearable="false"
              >
                <template #header>
                  <label class="block"
                    >Beasiswa: <span class="text-danger">*</span></label
                  >
                </template>
                <template v-slot:option="option">
                  <div class="flex justify-between p-1">
                    {{ option.value }} | {{ option.label }}
                  </div>
                </template>
              </input-datalists>
            </b-form-group>
            <b-form-group>
              <label for="nim_mahasiswa">NIM Mahasiswa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nim_mahasiswa"
                placeholder="Masukkan 9 digit NIM Mahasiswa"
                v-model="form.nim_mahasiswa"
                required
                @input="inputNim"
                type="number"
                max-length="9"
                min-length="9"
              />
            </b-form-group>
            <b-form-group>
              <label for="nama_mahasiswa">Nama Mahasiswa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nama_mahasiswa"
                v-model="form.nama_mahasiswa"
                required
                isDetail
              />
            </b-form-group>
            <b-row class="mt-5">
              <b-col>
                <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
              </b-col>
              <b-col>
                <b-btn
                  class="btn-block"
                  variant="success"
                  @click="createData"
                  :disabled="!isValidFormPks"
                  >Simpan</b-btn
                >
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <!-- ./END:Left -->
        <!-- BEGIN:Right -->
        <b-tab
          title="Surat Keputusan"
          title-link-class="font-weight-bold bg-light mx-2"
        >
          <b-form class="px-4">
            <label for="program">Program:</label
            ><span class="text-danger ml-1">*</span>
            <b-form-group>
              <b-form-radio
                inline
                id="program1"
                v-model="form.program"
                value="1"
                name="program"
                >Pendas</b-form-radio
              >
              <b-form-radio
                inline
                id="program2"
                v-model="form.program"
                value="2"
                name="program"
                >Non Pendas</b-form-radio
              >
              <b-form-radio
                inline
                id="program3"
                v-model="form.program"
                value="3"
                name="program"
                >PPS</b-form-radio
              >
            </b-form-group>
            <b-form-group>
              <label for="masa">Masa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="masa"
                v-model="form.masa"
                required
                type="number"
                max-length="5"
              />
            </b-form-group>
            <b-form-group>
              <input-datalists
                id="nomorSkRektor"
                class="w-full text-sm"
                placeholder="Masukkan 3 digit nomor SK Rektor"
                v-model="nomorSkRektor"
                label="label"
                required
                @search="getDataDetailSk"
                :options="listDataSk || []"
                :clearable="false"
              >
                <template #spinner="{ loading }">
                  <div v-if="loading" class="vs__spinner"></div>
                </template>
                <template #header>
                  <label class="block"
                    >Nomor SK Rektor: <span class="text-danger">*</span></label
                  >
                </template>
                <template v-slot:option="option">
                  <div class="flex justify-between p-1">
                    {{ option.label }} | {{ option.judul_sk }}
                  </div>
                </template>
              </input-datalists>
            </b-form-group>
            <b-form-group>
              <label for="tgl_sk_rektor">Tanggal SK Rektor:</label
              ><span class="text-danger ml-1">*</span>
              <flat-pickr
                id="tgl_sk_rektor"
                class="form-control bg-light cursor-not-allowed"
                :config="basicConfig"
                v-model="form.tgl_sk_rektor"
                placeholder="Tanggal SK Rektor"
                disabled
              ></flat-pickr>
            </b-form-group>
            <b-form-group>
              <label for="judul_sk">Judul SK:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="judul_sk"
                v-model="form.judul_sk"
                required
                isDetail
              />
            </b-form-group>
            <b-form-group>
              <input-datalists
                id="kode_beasiswa"
                class="w-full text-sm"
                :placeholder="
                  form.program == null
                    ? 'Pilih program lebih dulu'
                    : 'Pilih Beasiswa'
                "
                v-model="form.kode_beasiswa"
                label="label"
                required
                :options="listDataBeasiswa || []"
                :reduce="listDataBeasiswa => listDataBeasiswa.value"
                :disabled="form.program == null"
                :clearable="false"
              >
                <template #header>
                  <label class="block"
                    >Beasiswa: <span class="text-danger">*</span></label
                  >
                </template>
                <template v-slot:option="option">
                  <div class="flex justify-between p-1">
                    {{ option.value }} | {{ option.label }}
                  </div>
                </template>
              </input-datalists>
            </b-form-group>
            <b-form-group>
              <label for="nim_mahasiswa">NIM Mahasiswa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nim_mahasiswa"
                placeholder="Masukkan 9 digit NIM Mahasiswa"
                v-model="form.nim_mahasiswa"
                required
                @input="inputNim"
                type="number"
                max-length="9"
                min-length="9"
              />
            </b-form-group>
            <b-form-group>
              <label for="nama_mahasiswa">Nama Mahasiswa:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="nama_mahasiswa"
                v-model="form.nama_mahasiswa"
                required
                isDetail
              />
            </b-form-group>
            <b-row class="mt-5">
              <b-col>
                <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
              </b-col>
              <b-col>
                <b-btn
                  class="btn-block"
                  variant="success"
                  @click="createData"
                  :disabled="!isValidFormSk"
                  >Simpan</b-btn
                >
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <!-- ./END:Right -->
      </b-tabs>
    </b-modal>
    <!-- ./END:Modal Create -->
    <!-- BEGIN:Modal Delete -->
    <b-modal
      id="modal-delete"
      v-model="isModalDelete"
      centered
      title="Hapus Data Mahasiswa Beasiswa Kerjasama"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus data<br /><b
            >{{ modal.nim }} | {{ modal.nama_mahasiswa }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDelete = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteData"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Daftar Data Entry Beasiswa</h4>
          </template>
          <template v-slot:body>
            <div>
              <b-row>
                <b-col>
                  <b-btn
                    class="btn-block"
                    variant="success"
                    @click="showDataPendas"
                    >Pendas</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn
                    class="btn-block"
                    variant="secondary"
                    @click="showDataNonPendas"
                    >Non Pendas</b-btn
                  >
                </b-col>
                <b-col>
                  <b-btn class="btn-block" variant="info" @click="showDataPps"
                    >PPS</b-btn
                  >
                </b-col>
              </b-row>
            </div>
            <div class="align-items-center mt-4">
              <h3 class="font-weight-bold mb-2">
                {{
                  form.program == 1
                    ? 'Pendas'
                    : form.program == 2
                    ? 'Non Pendas'
                    : form.program == 3
                    ? 'Pascasarjana'
                    : ''
                }}
              </h3>
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filterEntryBeasiswa"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <b-button @click="showModalCreate" variant="primary"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingEntryBeasiswa"
                :filter="filterEntryBeasiswa"
                :per-page="perPageEntryBeasiswa"
                :current-page="currentPageEntryBeasiswa"
                @filtered="onFilteredEntryBeasiswa"
                show-empty
              >
                <template v-slot:cell(aksi)="listData">
                  <div class="d-flex">
                    <b-button
                      class="mx-1"
                      variant=" iq-bg-danger"
                      size="sm"
                      @click="showModalDelete(listData.item)"
                      ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
                    >
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div class="bg-light font-weight-bold mx-auto text-center">
                    <span class="text-primary">{{ table_message }}</span>
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPageEntryBeasiswa"
                      :options="pageOptionsEntryBeasiswa"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPageEntryBeasiswa"
                    :total-rows="totalRowsEntryBeasiswa"
                    :per-page="perPageEntryBeasiswa"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'kerjasama.entry-beasiswa',
  computed: {
    ...mapState('authService', ['token']),
    isValidFormPks() {
      const status =
        this.form.program &&
        this.form.masa &&
        this.kodePks &&
        this.form.nomor_pks_ut &&
        this.form.nomor_pks_mitra &&
        this.form.kode_beasiswa &&
        this.form.nim_mahasiswa &&
        this.form.nama_mahasiswa !== null
      return status
    },
    isValidFormSk() {
      const status =
        this.form.program &&
        this.form.masa &&
        this.nomorSkRektor &&
        this.form.tgl_sk_rektor &&
        this.form.judul_sk &&
        this.form.kode_beasiswa &&
        this.form.nim_mahasiswa &&
        this.form.nama_mahasiswa !== null
      return status
    }
  },
  mounted() {
    /** Innit Data */
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFilteredEntryBeasiswa(filteredItems) {
      this.totalRowsEntryBeasiswa = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Reset Value
     */
    resetForm() {
      /** PKS */
      this.form.masa = null
      this.kodePks = null
      this.form.nomor_pks_ut = null
      this.form.nomor_pks_mitra = null
      this.form.tipe_beasiswa = null
      this.form.kode_beasiswa = null
      this.form.nim_mahasiswa = null
      this.form.nama_mahasiswa = null
      /** Surat Keputusan */
      this.nomorSkRektor = null
      this.form.tgl_sk_rektor = null
      this.form.judul_sk = null
    },
    resetModal() {
      this.modal.masa = null
      this.modal.nim = null
      this.modal.nama_mahasiswa = null
    },
    resetInputPks() {
      this.kodePks = null
      this.form.nomor_pks_ut = null
      this.form.nomor_pks_mitra = null
      this.form.tipe_beasiswa = null
    },
    resetInputSk() {
      this.nomorSkRektor = null
      this.form.tgl_sk_rektor = null
      this.form.judul_sk = null
    },
    /**
     * Get data Entry Beasiswa
     */
    async getDataEntryBeasiswa_(kodeJenisProgram) {
      this.isLoadingEntryBeasiswa = true
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa?kodeJenisProgram=${kodeJenisProgram}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listData = res.data?.data
          /** If listData exist ? Set the initial number of items : Set table message */
          this.listData.length > 0
            ? (this.totalRowsEntryBeasiswa = this.listData.length)
            : (this.table_message = 'Data tidak ditemukan')
          this.isLoadingEntryBeasiswa = false
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get data list Beasiswa
     */
    async getDataListBeasiswa(kodeJenisProgram) {
      this.form.kode_beasiswa = null
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getBeasiswa?kodeJenisProgram=${kodeJenisProgram}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataBeasiswa = res.data.message
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Rules Input
     */
    /** Input Jenis Program */
    inputJenisProgram() {
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
    },
    /** Input NIM Mahasiswa */
    async inputNim() {
      if (this.form.nim_mahasiswa && this.form.nim_mahasiswa.length === 9) {
        const result = await axios({
          url: `${process.env.VUE_APP_URL_PUBLIC}/getNim?nim=${this.form.nim_mahasiswa}`,
          method: 'GET',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token
          }
        })
          .then(res => {
            this.listDataMahasiswa = res.data?.data
            if (this.listDataMahasiswa) {
              this.listDataMahasiswa.map(
                n => (this.form.nama_mahasiswa = n.nama_mahasiswa)
              )
            }
          })
          .catch(err => {
            console.log('ERROR', err)
            throw new Error(err)
          })
        return result
      } else {
        this.form.nama_mahasiswa = null
      }
    },
    /** Input Kode PKS */
    getDataDetailPks(kodePks) {
      const result = axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getDetailPKS?dataPks=${kodePks}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataPks = res.data?.getDataPks
          if (this.kodePks) {
            this.form.nomor_pks_ut = this.kodePks.nomor_pks_ut
            this.form.nomor_pks_mitra = this.kodePks.nomor_pks_mitra
            this.form.tipe_beasiswa = this.kodePks.tipe_beasiswa
          } else {
            this.form.nomor_pks_ut = null
            this.form.nomor_pks_mitra = null
            this.form.tipe_beasiswa = null
          }
          this.resetInputSk()
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /** Input Nomor SK Rektor */
    getDataDetailSk(nomorSkRektor) {
      const result = axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getDetailSK?dataSK=${nomorSkRektor}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataSk = res.data?.getDataSk
          if (this.nomorSkRektor) {
            this.form.tgl_sk_rektor = this.nomorSkRektor.tgl_sk_rektor
            this.form.judul_sk = this.nomorSkRektor.judul_sk
          } else {
            this.form.tgl_sk_rektor = null
            this.form.judul_sk = null
          }
          this.resetInputPks()
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Show Data
     */
    showDataPendas() {
      this.form.program = 1
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
    },
    showDataNonPendas() {
      this.form.program = 2
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
    },
    showDataPps() {
      this.form.program = 3
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
    },
    /**
     * Create Enry Beasiswa
     */
    showModalCreate() {
      this.resetForm()
      this.isModalCreate = true
    },
    closeModalCreate() {
      this.resetForm()
      this.isModalCreate = false
    },
    async createData() {
      console.log('Kode PKS', this.kodePks)
      console.log('Nomor SK', this.nomorSkRektor)
      console.log('Form', this.form)
      console.log('Mhs', this.listDataMahasiswa)
      /** Get id beasiswa */
      const beasiswa = this.listDataBeasiswa.find(
        b => b.value === this.form.kode_beasiswa
      )
      this.idBeasiswa = beasiswa.id_beasiswa
      console.log('Id Beasiswa', this.idBeasiswa)
      /** Get dasar beasiswa */
      if (this.kodePks == null) {
        this.dasarBeasiswa = 'SK'
      }
      if (this.nomorSkRektor == null) {
        this.dasarBeasiswa = 'PKS'
      }
      console.log('Dasar Beasiswa', this.dasarBeasiswa)
      /** Create */
      const bodyFormData = new FormData()
      bodyFormData.append(
        'kode_pks ',
        this.kodePks
          ? this.kodePks.kode_pks
          : this.nomorSkRektor.label.replace(/^\s+|\s+$/gm, '')
      )
      bodyFormData.append('dasarBeasiswa', this.dasarBeasiswa)
      bodyFormData.append('masa', this.form.masa)
      bodyFormData.append('nim', this.form.nim_mahasiswa)
      bodyFormData.append(
        'masa_registrasi_awal',
        this.listDataMahasiswa.map(mra => mra.masa_registrasi_awal)
      )
      bodyFormData.append(
        'masa_kurikulum',
        this.listDataMahasiswa.map(mk => mk.masa_kurikulum)
      )
      bodyFormData.append(
        'id_mhs',
        this.listDataMahasiswa.map(im => im.id_mhs)
      )
      bodyFormData.append('id_beasiswa', this.idBeasiswa)
      bodyFormData.append('nomor_pks_ut', this.form.nomor_pks_ut)
      bodyFormData.append('nomor_pks_mitra', this.form.nomor_pks_mitra)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/simpan`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err.response)
          this.isModalCreate = false
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
      this.resetForm()
      this.isModalCreate = false
    },
    /**
     * Delete Entry Beasiswa
     */
    showModalDelete(data) {
      this.resetModal()
      this.modal.masa = data.masa
      this.modal.nim = data.nim
      this.modal.nama_mahasiswa = data.nama_mahasiswa
      this.isModalDelete = true
    },
    async deleteData() {
      console.log('mmodal: ', this.modal)
      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/hapusBeasiswa?nim=${this.modal.nim}&masa=${this.modal.masa}`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err.response)
          this.isModalCreate = false
          this.$toast.error(err?.response?.data?.message)
          throw new Error(err)
        })
      /** Data Entry Beasiswa */
      this.getDataEntryBeasiswa_(this.form.program)
      /** Dropdown Beasiswa */
      this.getDataListBeasiswa(this.form.program)
      this.resetModal()
      this.isModalDelete = false
    },
    /**
     * Innit Data
     */
    async innitData() {
      // await Promise.all([])
    }
  },
  data() {
    return {
      kodePks: null,
      nomorSkRektor: null,
      // Tabel Entry Beasiswa Options
      isLoadingEntryBeasiswa: false,
      filterEntryBeasiswa: null,
      perPageEntryBeasiswa: 5,
      pageOptionsEntryBeasiswa: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPageEntryBeasiswa: 1,
      totalRowsEntryBeasiswa: 1,
      table_message: 'Silahkan pilih salah satu program',
      // isModal
      isModalCreate: null,
      isModalDelete: null,
      // Date Picker
      basicConfig: {
        dateFormat: 'd-m-Y'
      },
      // Form
      form: {
        /** PKS */
        program: null,
        masa: null,
        nomor_pks_ut: null,
        nomor_pks_mitra: null,
        tipe_beasiswa: null,
        kode_beasiswa: null,
        nim_mahasiswa: null,
        nama_mahasiswa: null,
        /** Surat Keputusan */
        tgl_sk_rektor: null,
        judul_sk: null
      },
      // Modal
      modal: {
        masa: null,
        nim: null,
        nama_mahasiswa: null
      },
      // Data List
      listDataBeasiswa: [],
      listDataPks: [],
      listDataSk: [],
      // List Data
      listData: [],
      // Table Pendas
      thead: [
        { label: 'Masa', key: 'masa', class: 'text-center' },
        { label: 'Nim', key: 'nim', class: 'text-center' },
        {
          label: 'Nama Mahasiswa',
          key: 'nama_mahasiswa',
          class: 'text-center w-50'
        },
        { label: 'Kode Beasiswa', key: 'kode_beasiswa', class: 'text-center' },
        {
          label: 'Keterangan Beasiswa',
          key: 'keterangan_beasiswa',
          class: 'text-center'
        },
        { label: 'User Create', key: 'user_create', class: 'text-center' },
        { label: 'Aksi', key: 'aksi', class: 'text-center' }
      ],
      listDataPendas: [],
      listDataNonPendas: [],
      listDataPps: []
    }
  },
  beforeRouteEnter(to, from, next) {
    const token = store.state.authService.token
    const idUnitKs = store.state.authService.idUnitKs
    axios({
      url: `${process.env.VUE_APP_URL_PUBLIC}/entryBeasiswa/getBukaTutupBeasiswa`,
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    })
      .then(() => {
        if (MENU_ROLE.KERJASAMA_ENTRY_BEASISWA.includes(String(idUnitKs))) {
          return next()
        } else {
          Vue.$toast.error('Anda tidak memiliki hak akses')
          return next({ name: 'sidebar.dashboard' })
        }
      })
      .catch(err => {
        Vue.$toast.error(err.response.data.message)
        return next({ name: 'sidebar.dashboard' })
      })
  }
}
</script>
